<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :height="!screenMobile ? 800 : 700"
      :src="require('@/assets/hero/heroDidatics.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .7), rgba(0,0,0,.3)"
    >
      <v-container
        class="px-2 py-2"
        :class="classes"
      >
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="800"
          width="90%"
        >
          <base-heading
            size="text-h2"
            title="Educación post covid en Latinoamérica"
            weight="900"
          />

          <base-body
            :html="textDescription"
          />
          <div
            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
            class="d-flex flex-wrap pt-sm-10"
          >
            <v-row align="baseline">
              <v-col
                cols="12"
                sm="6"
                lg="4"
              >
                <events-data
                  icon="mdi-calendar-month-outline"
                  :city="city1"
                  :date="date1"
                  :hour="hour1"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="4"
              >
                <events-data
                  icon="mdi-calendar-month-outline"
                  :city="city2"
                  :date="date2"
                  :hour="hour2"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                lg="4"
              >
                <base-btn
                  v-smooth-scroll="{ duration: 1000 }"
                  href="#contactForm"
                  :block="!screenMobile"
                >
                  Registro
                </base-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="8">
                <small>
                  *Es obligatorio realizar un pre-registro para confirmar su cupo. (Cupo Limitado)
                </small>
              </v-col>
            </v-row>
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import EventsData from '../../components/base/EventsData.vue'
  import { EVENT_1, EVENT_2 } from '../../constanst'

  export default {
    name: 'SectionHero',
    components: { EventsData },
    provide: {
      theme: { isDark: true },
    },
    data: () => ({
      city1: EVENT_1.city,
      city2: EVENT_2.city,
      date1: EVENT_1.date,
      date2: EVENT_2.date,
      hour1: EVENT_1.hour,
      hour2: EVENT_2.hour,
      textDescription: `En el mes de marzo de 2020, nuestra región recibió una alerta de emergencia
            nunca antes vista en términos de educación. Nuestros docentes enfrentaron un
            gran dilema de cómo poder ayudar a nuestros estudiantes. En ese momento se
            tomaron muchas decisiones que ayudaron a solucionar la situación de forma
            parcial. Sin embargo, 2 años después necesitamos tener políticas orientadas
            a solucionar este tipo de emergencia y lo más importante, que esto permita
            mejorar el proceso de aprendizaje de nuestro alumnado.`,
    }),
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      classes () {
        return screen.width > 768 ? ['fill-height'] : []
      },
      screenMobile () {
        return screen.width > 768
      },
    },
  }
</script>
